
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




























































/* stylelint-disable selector-max-combinators, selector-max-compound-selectors */

.anders-wonen__component {
  @include default-margin(0.6);

  &:last-child {
    margin-bottom: $spacing * 3;

    @include mq(l) {
      margin-bottom: $spacing * 4;
    }
  }

  ::v-deep .flexible-section__intro .title {
    font-size: 2.8rem; // same as h2
    line-height: 1;

    em {
      color: $c-dark-grey;
    }

    @include mq(m) {
      font-size: 3.6rem; // same as h2
    }

    @include mq(xxl) {
      font-size: 4.8rem; // same as h2
    }
  }
}
