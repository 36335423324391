
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




























































































































































.aw-hero {
  position: relative;
  overflow: hidden;
  width: 100%;
  aspect-ratio: 4/3;
  margin-top: var(--header-height);

  &::before {
    @include get-all-space;

    z-index: 1;
    content: '';
    background: rgba($c-black, 0.3);
  }

  @include mq(l) {
    max-height: 75vh;
    aspect-ratio: 16/9;

    &::before {
      content: none;
    }
  }
}

.aw-hero__title {
  @extend %text-center;
  @include center-xy;

  z-index: 2;
  width: 80%;
  text-shadow: 0 0 1.3rem rgb(114, 114, 114);

  > * {
    display: block;
    color: $c-white;
  }

  .aw-hero.hidden & {
    opacity: 0;
  }
}

.aw-hero__visual {
  @include get-all-space;
}

.aw-hero__visual__picture {
  @include image-fit;

  z-index: 1;
  will-change: opacity;
}

.aw-hero__visual__video {
  @include image-fit;
}

.aw-hero__loader {
  @include center-xy;

  z-index: 2;
}
